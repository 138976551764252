import * as React from "react";
import { Edit, SimpleForm, TextInput, BooleanInput } from "react-admin";
import ImageUpload from "../../subComponents/ImageUpload";
import authProvider from "../../authProvider";
import Grid from "@mui/material/Grid";

const ExtraTitle = ({ record }) => {
	return <span>Editing Extra {record ? `"${record.title}"` : ""}</span>;
};

const ExtraEdit = (props) => {
	const disabled = authProvider.isReadOnly();

	return (
		<Edit title={<ExtraTitle />} {...props}>
			<SimpleForm>
				<h1>Edit Extra</h1>
				<Grid container spacing={1} style={{ width: "100%" }}>
					<Grid item xs={6}>
						{/*<h4>Type:</h4><SelectInput
					source="type"
					choices={[
						{ id: "remote", name: "Remote" },
						{ id: "native", name: "Native" },
					]}
					disabled={disabled}
				/>*/}
						<h4>Title:</h4>
						<TextInput required source="title" disabled={disabled} />
						<h4>Unique Identifier:</h4>
						<TextInput required source="identifier" label="Identifier" disabled={disabled} />
						<h4>Tint Color (HEX CODE):</h4>
						<TextInput required source="color" label="Tint Color" disabled={disabled} />
						<h4>Web Page URL:</h4>
						<TextInput source="url" disabled={disabled} />
						<h4>Android Web Page URL (Optional):</h4>
						<TextInput source="androidURL" label="Android URL" disabled={disabled} />
						<h4>Tile Image:</h4>
						<div>
							<ImageUpload source="image" disabled={disabled} />
						</div>
					</Grid>
					<Grid item xs={6}>
						<h4>Enabled:</h4>
						<BooleanInput source="enabled" disabled={disabled} />
						<h4>Requires Browser Controls:</h4>
						<BooleanInput source="hasBrowserControls" disabled={disabled} />
					</Grid>
				</Grid>
			</SimpleForm>
		</Edit>
	);
};

export default ExtraEdit;
