import * as React from "react";
import { Edit, SimpleForm, TextInput, ReferenceArrayInput, AutocompleteArrayInput } from "react-admin";
import authProvider from "../../authProvider";

const PodcastGroupTitle = ({ record }) => {
	return <span>Editing Podcast Group {record ? `"${record.name}"` : ""}</span>;
};

const PodcastGroupEdit = (props) => {
	const disabled = authProvider.isReadOnly();

	return (
		<Edit title={<PodcastGroupTitle />} {...props}>
			<SimpleForm>
				<h1>Edit Podcast Group</h1>
				<h4>Name:</h4>
				<TextInput required source="name" disabled={disabled} />
				<h4>Podcasts In Group:</h4>{" "}
				<ReferenceArrayInput source="podcasts" label="Podcasts Featured On" reference="podcasts">
					<AutocompleteArrayInput optionText="name" disabled={disabled} />
				</ReferenceArrayInput>
			</SimpleForm>
		</Edit>
	);
};

export default PodcastGroupEdit;
