import * as React from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { SimpleList, List, Datagrid, TextField, EditButton } from "react-admin";
import authProvider from "../../authProvider";

const PodcastList = (props) => {
	const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));

	return (
		<>
			<div className="headingContainer">
				<h1>Live Show</h1>
			</div>
			<List hasCreate={!authProvider.isReadOnly()} sort={{ field: "start", order: "ASC" }} title="All Live Shows" {...props} bulkActionButtons={false}>
				{isSmall ? (
					<SimpleList primaryText={(record) => record.emailAddress} />
				) : (
					<Datagrid bulkActionButtons={false} rowClick="edit">
						<TextField source="title" />
						<TextField source="subtitle" />
						<EditButton />
					</Datagrid>
				)}
			</List>
		</>
	);
};

export default PodcastList;
