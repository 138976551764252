import React, { Component } from "react";

import { AppBar, Toolbar, IconButton, MenuItem, Menu } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import AccountCircle from "@mui/icons-material/AccountCircle";
import Logo from "./logo-white.png";
import Container from "@mui/material/Container";
import authProvider from "../authProvider";

const classes = {
	toolbar: {
		display: "flex",
		justifyContent: "space-between",
		padding: "0px 10px",
		background: `linear-gradient(to right, ${process.env.REACT_APP_PRIMARY_COL}, ${process.env.REACT_APP_SECONDARY_COL})`,
	},
	drawerContainer: {
		padding: "20px 30px",
	},
};

class Appbar extends Component {
	constructor(props) {
		super(props);
		this.state = {
			anchorEl: null,
			isMenuOpen: false,
		};
	}

	render() {
		const { anchorEl, isMenuOpen } = this.state;

		const handleProfileMenuOpen = (event) => {
			this.setState({ anchorEl: event.currentTarget, isMenuOpen: true });
		};

		const handleMenuClose = () => {
			this.setState({ anchorEl: null, isMenuOpen: false });
		};

		const logoutAndClose = () => {
			authProvider.logout();
			window.location.replace("/#/login");
		};

		const menuId = "primary-search-account-menu";

		const displayDesktop = () => {
			return (
				<Toolbar disableGutters={true} style={classes.toolbar}>
					<RouterLink to="/">
						<img src={Logo} style={{ height: "50px", verticalAlign: "bottom", padding: "10px" }} alt={"Broad Logo"} />
					</RouterLink>
					<div>
						<IconButton aria-controls="primary-search-account-menu" aria-haspopup="true" color="inherit" onClick={handleProfileMenuOpen}>
							<AccountCircle style={{ fill: "white" }} />
						</IconButton>
					</div>
				</Toolbar>
			);
		};

		return (
			<div>
				<Container maxWidth="lg">
					<header>
						<AppBar elevation={0} style={classes.header}>
							{displayDesktop()}
						</AppBar>
						<Menu
							anchorEl={anchorEl}
							anchorOrigin={{ vertical: "top", horizontal: "right" }}
							id={menuId}
							keepMounted
							transformOrigin={{ vertical: "top", horizontal: "right" }}
							open={isMenuOpen}
							onClose={handleMenuClose}
						>
							<MenuItem onClick={logoutAndClose}>Sign Out</MenuItem>
						</Menu>
					</header>
				</Container>
			</div>
		);
	}
}

export default Appbar;
