import PollEdit from "./PollEdit";
import PollList from "./PollList";
import PollCreate from "./PollCreate";

const resource = {
	list: PollList,
	edit: PollEdit,
	create: PollCreate,
	options: {
		dashUrl: "/#/polls",
		label: "Polls",
		priority: 10,
		nestedGroup: 1,
	},
};

export default resource;
