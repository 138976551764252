import * as React from "react";
import { Create, SimpleForm, TextInput, ReferenceArrayInput, AutocompleteArrayInput } from "react-admin";

const PodcastGroupCreate = (props) => (
	<Create title="Create a Podcast Group" {...props}>
		<SimpleForm>
			<h1>Create a Podcast Group</h1>
			<h4>Name:</h4>
			<TextInput required source="name" />
			<h4>Podcasts In Group:</h4>
			<ReferenceArrayInput source="podcasts" label="Podcasts Featured On" reference="podcasts">
				<AutocompleteArrayInput optionText="name" />
			</ReferenceArrayInput>
		</SimpleForm>
	</Create>
);

export default PodcastGroupCreate;
