import React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";

import StarBorder from "@mui/icons-material/StarBorder";
import UserIcon from "@mui/icons-material/PersonAdd";
import SwitchAccountIcon from "@mui/icons-material/SwitchAccount";
// import TabIcon from "@mui/icons-material/Tab";
import GridViewIcon from "@mui/icons-material/GridView";
import PodcastsIcon from "@mui/icons-material/Podcasts";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import ReadMoreIcon from "@mui/icons-material/ReadMore";
import AttachmentIcon from "@mui/icons-material/Attachment";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";

const classes = {
	root: {
		backgroundColor: "transparent",
		width: "350px",
		marginTop: "35px",
		marginRight: "15px",
		marginLeft: "15px",
	},

	bold: {
		fontWeight: "bold !important",
	},

	baseStyle: { margin: "5px", width: "250px", borderRadius: "5px", marginLeft: "10px" },
};

export default function NestedList() {
	function isPage(name) {
		let finalName = name;

		if (finalName === "PodcastCategorys") {
			finalName = "Categories";
		}

		if (finalName === "PodcastGroups") {
			finalName = "Groups";
		}

		if (finalName === "Polls") {
			finalName = "Temperature Checks";
		}

		if (finalName === "Links") {
			finalName = "Socials";
		}

		if (finalName === "LiveAudioStreams") {
			finalName = "Live Shows";
		}

		if (window.location.href.toLowerCase().includes(name.toLowerCase())) {
			return [
				true,
				<Typography type="body2" style={{ fontWeight: "bold" }}>
					{finalName}
				</Typography>,
			];
		} else {
			return [false, `${finalName}`];
		}
	}

	const selectedStyle = { background: "#d3d3d370", ...classes.baseStyle };
	const unselectedStyle = { ...classes.baseStyle };

	return (
		<List component="nav" className={classes.root}>
			<ListItem button component="a" href="/polls" style={isPage("Polls")[0] ? selectedStyle : unselectedStyle}>
				<ListItemIcon>
					<QuestionAnswerIcon />
				</ListItemIcon>
				<ListItemText primary={isPage("Polls")} />
			</ListItem>
			<ListItem button component="a" href="/hosts" style={isPage("Hosts")[0] ? selectedStyle : unselectedStyle}>
				<ListItemIcon>
					<SwitchAccountIcon />
				</ListItemIcon>
				<ListItemText primary={isPage("Hosts")} />
			</ListItem>
			<Divider />
			<ListItem button component="a" href="/podcasts" style={isPage("Podcasts")[0] ? selectedStyle : unselectedStyle}>
				<ListItemIcon>
					<PodcastsIcon />
				</ListItemIcon>
				<ListItemText primary={isPage("Podcasts")} />
			</ListItem>
			<ListItem button component="a" href="/liveAudioStreams" style={isPage("LiveAudioStreams")[0] ? selectedStyle : unselectedStyle}>
				<ListItemIcon>
					<PlayCircleIcon />
				</ListItemIcon>
				<ListItemText primary={isPage("LiveAudioStreams")} />
			</ListItem>
			<ListItem button component="a" href="/podcastCategorys" style={isPage("Podcast Categorys")[0] ? selectedStyle : unselectedStyle}>
				<ListItemIcon>
					<StarBorder />
				</ListItemIcon>
				<ListItemText primary={isPage("PodcastCategorys")} />
			</ListItem>
			<ListItem button component="a" href="/podcastGroups" style={isPage("Podcast Groups")[0] ? selectedStyle : unselectedStyle}>
				<ListItemIcon>
					<ReadMoreIcon />
				</ListItemIcon>
				<ListItemText primary={isPage("PodcastGroups")} />
			</ListItem>
			<Divider />
			{/*
			<ListItem button component="a" href="/tabs" style={isPage("Tabs")[0] ? selectedStyle : unselectedStyle}>
				<ListItemIcon>
					 <TabIcon />
				</ListItemIcon>
				 <ListItemText primary={isPage("Tabs")} />
			</ListItem>
			*/}
			<ListItem button component="a" href="/extras" style={isPage("Extras")[0] ? selectedStyle : unselectedStyle}>
				<ListItemIcon>
					<GridViewIcon />
				</ListItemIcon>
				<ListItemText primary={isPage("Extras")} />
			</ListItem>
			<ListItem button component="a" href="/links" style={isPage("Links")[0] ? selectedStyle : unselectedStyle}>
				<ListItemIcon>
					<AttachmentIcon />
				</ListItemIcon>
				<ListItemText primary={isPage("Links")} />
			</ListItem>
			<Divider />
			<ListItem button component="a" href="/users" style={isPage("Users")[0] ? selectedStyle : unselectedStyle}>
				<ListItemIcon>
					<UserIcon />
				</ListItemIcon>
				<ListItemText primary={isPage("Users")} />
			</ListItem>
		</List>
	);
}
