import PodcastList from "./PodcastList";
import PodcastCreate from "./PodcastCreate";
import PodcastEdit from "./PodcastEdit";

const resource = {
	list: PodcastList,
	create: PodcastCreate,
	edit: PodcastEdit,
};

export default resource;
