import * as React from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { SimpleList, List, Datagrid, Filter, TextField, ReferenceArrayField, EditButton, SingleFieldList, ChipField, FunctionField } from "react-admin";
import SortOrderIcons from "../../subComponents/SortOrderIcons";
import authProvider from "../../authProvider";

const PodcastFilter = (props) => (
	<Filter {...props}>
		<TextField label="Search" source="q" alwaysOn />
	</Filter>
);

const PodcastList = (props) => {
	const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));

	return (
		<>
			<div className="headingContainer">
				<h1>Podcast Groups</h1>
			</div>
			<List hasCreate={!authProvider.isReadOnly()} sort={{ field: "sortOrder", order: "ASC" }} title="All Podcasts" {...props} filters={<PodcastFilter />} bulkActionButtons={false}>
				{isSmall ? (
					<SimpleList primaryText={(record) => record.emailAddress} />
				) : (
					<Datagrid bulkActionButtons={false} rowClick="edit">
						<TextField type="name" source="name" />
						<ReferenceArrayField source="podcasts" label="Podcasts In Group" reference="podcasts">
							<SingleFieldList>
								<ChipField source="name" />
							</SingleFieldList>
						</ReferenceArrayField>
						<FunctionField label="Order" render={(record) => <SortOrderIcons rowData={record} table={"podcast_show_group"} />} />
						<EditButton />
					</Datagrid>
				)}
			</List>
		</>
	);
};

export default PodcastList;
