import * as React from "react";
import { Edit, SimpleForm, TextInput, BooleanInput } from "react-admin";
import ImageUpload from "../../subComponents/ImageUpload";
import authProvider from "../../authProvider";
import Grid from "@mui/material/Grid";

const TabTitle = ({ record }) => {
	return <span>Editing Tab {record ? `"${record.title}"` : ""}</span>;
};

const TabEdit = (props) => {
	const disabled = authProvider.isReadOnly();

	return (
		<Edit title={<TabTitle />} {...props}>
			<SimpleForm>
				<h1>Edit Tab</h1>
				<Grid container spacing={1} style={{ width: "100%" }}>
					<Grid item xs={6}>
						{/*<h4>Type:</h4>
				<SelectInput
					source="type"
					choices={[
						{ id: "remote", name: "Remote" },
						{ id: "native", name: "Native" },
					]}
					disabled={disabled}
				/>*/}
						<h4>Title:</h4>
						<TextInput required source="title" disabled={disabled} />
						<h4>Unique Identifier:</h4>
						<TextInput required source="identifier" label="Identifier" disabled={disabled} />
						<h4>URL (If Any):</h4>
						<TextInput source="url" disabled={disabled} />
						<h4>Android URL (If Any):</h4>
						<TextInput source="androidURL" label="Android URL" disabled={disabled} />
						<h4>Icon:</h4>
						<div>
							<ImageUpload source="image" disabled={disabled} />
						</div>
					</Grid>
					<Grid item xs={6}>
						<h4>Enabled:</h4>
						<BooleanInput source="enabled" disabled={disabled} />
						<h4>Has Browser Controls:</h4>
						<BooleanInput source="hasBrowserControls" disabled={disabled} />
					</Grid>
				</Grid>
			</SimpleForm>
		</Edit>
	);
};

export default TabEdit;
