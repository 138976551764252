import React from "react";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";
import Grid from "@mui/material/Grid";

//SEN components
import authProvider from "../authProvider";
import HomeBanner from "./HomeBanner";

const themeCSS = {
	root: {
		width: "calc(100vw - 80px)",
		height: "calc(100vh - 80px)",
		padding: "40px",
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		borderRadius: "5px",
		color: process.env.REACT_APP_PRIMARY_COL,
	},
	fullHeight: {
		height: "calc(100vh - 80px)",
		padding: "0px",
	},
	w100: {
		width: "100%",
	},
	w50: {
		width: "50%",
	},
	p40: {
		padding: "40px",
	},
	paddingOutline: {
		padding: "40px",
	},
	floatLeft: {
		display: "inline",
		float: "left",
	},
	floatRight: {
		display: "inline",
		textAlign: "right",
		float: "right",
	},
	inline: {
		display: "inline",
	},
	headerBold: {
		fontWeight: 800,
		fontSize: "60px",
	},
	paddingLRBoxR: {
		padding: "0 5% 0 calc(5% + 40px)",
	},
	paddingLRBoxL: {
		padding: "0 12.5% 0 12.5%",
	},
	paddingLR12Percent: {
		padding: "0 12.5%",
	},
	paddingBottom20: {
		paddingBottom: "20px",
	},
	marginBottom5: {
		marginBottom: "5px",
	},
	marginBottom20: {
		marginBottom: "20px",
	},
	marginBottom30: {
		marginBottom: "30px",
	},
	verticalAlign: {
		display: "flex",
		alignItems: "center",
	},
	textCenter: { textAlign: "center" },
	submitButton: {
		background: process.env.REACT_APP_PRIMARY_COL,
		color: "#fff",
		fontWeight: "bold",
		fontSize: "22px",
		height: "60px",
		textTransform: "none",
		"&:hover": {
			background: "transparent",
			border: "1px solid " + process.env.REACT_APP_PRIMARY_COL,
			color: process.env.REACT_APP_PRIMARY_COL,
		},
	},
	colorTertiary: {
		color: process.env.REACT_APP_PRIMARY_COL,
	},
	secondaryButton: {
		color: process.env.REACT_APP_PRIMARY_COL,
		fontWeight: "bold",
		fontSize: "22px",
		height: "60px",
		textTransform: "none",
	},
	inputText: {
		borderColor: process.env.REACT_APP_PRIMARY_COL,
		color: process.env.REACT_APP_PRIMARY_COL,
		borderWidth: "20px",

		"& label": {
			color: process.env.REACT_APP_PRIMARY_COL,
			fontSize: "25px",
		},
		"& .Mui-focused": {
			color: process.env.REACT_APP_PRIMARY_COL,
		},
		"& .MuiInputBase-root": {
			color: process.env.REACT_APP_PRIMARY_COL,
			height: "60px",
		},
		"& .MuiInput-underline:after": {
			color: process.env.REACT_APP_PRIMARY_COL,
			borderBottomColor: process.env.REACT_APP_PRIMARY_COL,
		},
		"& .MuiOutlinedInput-root": {
			fontSize: "25px",

			"& fieldset": {
				color: process.env.REACT_APP_PRIMARY_COL,
				borderColor: process.env.REACT_APP_PRIMARY_COL,
			},
			"&:hover fieldset": {
				color: process.env.REACT_APP_PRIMARY_COL,
				borderColor: process.env.REACT_APP_PRIMARY_COL,
			},
			"&.Mui-focused fieldset": {
				color: process.env.REACT_APP_PRIMARY_COL,
				borderColor: process.env.REACT_APP_PRIMARY_COL,
			},
		},
	},
	bgImage: {
		width: "100vw",
		overflow: "visible",
	},
	hideMobile: {
		display: "block",
	},
	showMobile: {
		display: "none",
	},
	bg: {
		backgroundImage: `linear-gradient(to left,  ${process.env.REACT_APP_PRIMARY_COL}, ${process.env.REACT_APP_SECONDARY_COL})`,
		backgroundRepeat: "no-repeat",
		borderRadius: "5px",
	},
	bottomDiv: {
		bottom: "0px",
		position: "absolute",
		width: "75%",
		textAlign: "center",
	},
	relative: {
		position: "relative",
		height: "100%",
	},
	link: {
		textDecoration: "none",
		marginRight: "7px",
		color: process.env.REACT_APP_PRIMARY_COL,
	},
	mwFull: {
		maxWidth: "500px !important",
	},
	form: {
		height: "100%",
	},
};

const themeCSSMobile = {
	root: {
		margin: "0px",
		padding: "0px 0px",
		width: "100%",
	},
	paddingOutline: {
		padding: "10px",
	},
	paddingLRBoxR: {
		paddingBottom: "0px",
	},
	paddingLRBoxL: {
		paddingBottom: "0px",
	},
	paddingLR12Percent: {
		paddingBottom: "0px",
	},

	hideMobile: {
		display: "none",
	},
	showMobile: {
		display: "block",
	},
	bg: {
		backgroundImage: `linear-gradient(to left,  ${process.env.REACT_APP_PRIMARY_COL}, ${process.env.REACT_APP_SECONDARY_COL})`,
	},
	bottomDiv: {
		width: "88%",
		bottom: "25px",
	},
	form: {
		height: "auto",
		maxHeight: "50vh",
	},
};

class LoginPage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			username: "",
			password: "",
			loading: false,
			error: "",
			width: window.innerWidth,
		};
		this.handleResize = this.handleResize.bind(this);
	}

	componentDidMount() {
		window.addEventListener("resize", this.handleResize);
	}
	componentWillUnmount() {
		window.addEventListener("resize", null);
	}

	async handleLogin(username, password) {
		//Autocomplete check
		if (!username) {
			let el = document.getElementById("standard-email-input");
			if (el) {
				username = el.value;
			}
		}

		if (!password) {
			let el2 = document.getElementById("standard-password-input");
			if (el2) {
				password = el2.value;
			}
		}
		this.setState({ loading: true });
		authProvider
			.login({ username, password })
			.then(() => {
				setTimeout(() => window.location.replace("/users"), 500);
			})
			.catch(() => {
				this.setState({ error: "Incorrect email/password combination, please try again." });
				this.setState({ loading: false });
			});
	}

	handleResize(width, event) {
		this.setState({ width: window.innerWidth });
	}

	render() {
		const styles = this.state.width > 900 ? themeCSS : { ...themeCSS, ...themeCSSMobile };

		return (
			<div style={styles.root}>
				<div style={styles.bgImage}>
					<Grid container style={{ ...styles.paddingOutline, ...styles.fullHeight }}>
						<Grid item md={6} sm={12} xs={12} style={{ ...styles.p40, ...styles.verticalAlign, ...styles.paddingLRBoxL, ...styles.bg }}>
							<HomeBanner />
						</Grid>
						<Grid item md={6} sm={12} xs={12} style={{ ...styles.form, ...styles.p40, ...styles.verticalAlign, ...styles.paddingLRBoxR, ...styles.relative }}>
							<div style={{ ...styles.root }}>
								<div>
									<Typography variant="h4" align="left" style={{ ...styles.paddingBottom20, fontWeight: "bold", color: process.env.REACT_APP_PRIMARY_COL }}>
										Login
									</Typography>

									{this.state.error.length > 1 && (
										<Alert severity="error" style={styles.marginBottom20}>
											{this.state.error}
										</Alert>
									)}

									<form autoComplete="off">
										<div style={{ ...styles.w100, ...styles.marginBottom30 }}>
											<TextField
												id="standard-email-input"
												className="loginInput"
												label="Email"
												type="email"
												autoComplete="username"
												style={{ ...styles.w100, ...styles.inputText }}
												variant="outlined"
												InputLabelProps={{
													shrink: true,
												}}
												onChange={(event) =>
													this.setState({
														username: event.target.value,
													})
												}
											/>
										</div>
										<div style={styles.w100}>
											<TextField
												id="standard-password-input"
												className="loginInput"
												label="Password"
												type="password"
												autoComplete="password"
												style={{ ...styles.w100, ...styles.inputText }}
												variant="outlined"
												InputLabelProps={{
													shrink: true,
												}}
												onChange={(event) => this.setState({ password: event.target.value })}
											/>
										</div>
										<div style={styles.inline}>
											<div style={{ ...styles.w50, ...styles.floatRight, ...styles.marginBottom20 }}>
												<Link href="/forgot" style={styles.colorTertiary}>
													Reset Password
												</Link>
											</div>
										</div>
										<div style={{ ...styles.w100, ...styles.marginBottom5 }}>
											<Button
												size="large"
												className={"MuiButton-containedPrimary"}
												style={{ ...styles.w100, ...styles.submitButton }}
												onClick={(event) => {
													event.preventDefault();
													this.handleLogin(this.state.username, this.state.password);
												}}
												type="submit"
											>
												{!this.state.loading ? (
													<>Log In</>
												) : (
													<CircularProgress size={30} className={"MuiButton-containedPrimary"} style={{ ...styles.colorTertiary, background: "none" }} />
												)}
											</Button>
										</div>
									</form>
								</div>
							</div>
						</Grid>
					</Grid>
				</div>
			</div>
		);
	}
}

export default LoginPage;
