import * as React from "react";
import { Create, SimpleForm, TextInput, NumberInput, DateInput, TimeInput, FormDataConsumer } from "react-admin";
import Grid from "@mui/material/Grid";
import ImageUpload from "../../subComponents/ImageUpload";

const PodcastCreate = (props) => (
	<Create title="Create a Live Show" {...props}>
		<SimpleForm>
			<h1>Create Live Show</h1>
			<Grid container spacing={1} style={{ width: "100%" }}>
				<Grid item xs={6}>
					<h4>Title:</h4>
					<TextInput required source="title" />
					<h4>Subtitle:</h4>
					<TextInput source="subtitle" label="Subtitle" />
					<h4>Stream URL:</h4>
					<TextInput required source="streamURL" label="Stream URL" />
					<h4>Start Date:</h4>
					<DateInput required source="nextDate" />
					<div style={{ width: "100%", overflow: "auto" }}>
						<div style={{ display: "inline", float: "left", marginRight: "10px", width: "40%" }}>
							<h4>Start Time:</h4>
							<TimeInput required source="start" />
						</div>
						<div style={{ display: "inline", float: "left", width: "40%" }}>
							<h4>End Time:</h4>
							<TimeInput required source="end" />
						</div>
					</div>
					<h4>Repeat Interval:</h4>
					<NumberInput source="repeatInterval" label="Timeslot will repeat every x days" />
					<h4>Audio Player Image:</h4>
					<div>
						<ImageUpload source="image" />
					</div>
				</Grid>
				<Grid item xs={6}>
					<h4>Featured Color (HEX):</h4>
					<TextInput required source="featuredColor" />
					<h4>Featured Label:</h4>
					<TextInput required source="featuredLabel" />
					<h4>Text-In Number:</h4>
					<TextInput required source="sms" label="Number" />
					<h4>Call-In Number:</h4>
					<TextInput required source="phone" label="Number" />
					<h4>Example:</h4>
					<FormDataConsumer>
						{({ formData }) => {
							return (
								<div
									className="MuiButton-containedPrimary"
									style={{
										marginTop: "5px",
										width: "350px",
										height: "200px",
										borderRadius: "10px",
										padding: "10px",
										position: "relative",
									}}
								>
									<div style={{ position: "absolute", bottom: "10px" }}>
										<div style={{ fontWeight: 600, fontSize: "20px", marginBottom: "6px" }}>
											{formData.title ? formData.title : "Title"}
										</div>
										<div style={{ fontWeight: 600, fontSize: "10px", marginBottom: "4px" }}>
											{formData.subtitle ? formData.subtitle.toUpperCase() : "SUBTITLE"}
										</div>
										<div style={{ fontWeight: 600, fontSize: "12px" }}>
											<div
												style={{
													position: "relative",
													display: "inline",
													float: "left",
													marginRight: "7px",
													background: `${formData.featuredColor ? formData.featuredColor : "#FF5252"}`,
													borderRadius: "7px",
													textAlign: "center",
												}}
											>
												<span
													style={{
														color: "#FF7272",
														fontSize: "30px",
														lineHeight: "7px",
														position: "absolute",
														left: "5px",
														top: "1px",
													}}
												>
													{"•"}
												</span>
												<span style={{ float: "left", marginLeft: "20px", marginRight: "10px" }}>
													{formData.featuredLabel ? formData.featuredLabel.toUpperCase() : "LABEL"}
												</span>
											</div>
											<span>
												{new Date(formData.start || 0).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })} -{" "}
												{new Date(formData.end || 0).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })} TODAY
											</span>
										</div>
									</div>
								</div>
							);
						}}
					</FormDataConsumer>
				</Grid>
			</Grid>
		</SimpleForm>
	</Create>
);

export default PodcastCreate;
