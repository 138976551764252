const AuthProvider = {
  // called when the user attempts to log in
  login: ({ username, password }) => {
    const request = new Request(
      process.env.REACT_APP_API_URL + "/auth/attempts",
      {
        method: "POST",
        headers: new Headers({
          "Content-Type": "application/json",
        }),
        body: JSON.stringify({ emailAddress: username, password }),
      }
    );

    return fetch(request)
      .then((response) => response.json())
      .then((result) => {
        if (result.errorMessage) {
          return Promise.reject(result.errorMessage);
        }

        localStorage.setItem("username", result.emailAddress);
        localStorage.setItem("role", result.role);
        localStorage.setItem("authorization", result.token.key);
        localStorage.setItem(
          "expiry",
          result.token.expiry + result.token.created
        );
        return Promise.resolve();
      });
  },
  // called when the user clicks on the logout button
  logout: () => {
    localStorage.removeItem("role"); //
    localStorage.removeItem("authorization");
    localStorage.removeItem("expiry"); // Clear expiry time to reset for next login
    localStorage.removeItem("username"); // Clear expiry time to reset for next login
    return Promise.resolve();
  },
  // called when the API returns an error
  checkError: ({ status }) => {
    if (status === 401 || status === 403) {
      localStorage.removeItem("role");
      localStorage.removeItem("authorization");
      localStorage.removeItem("username");
      return Promise.reject();
    }
    return Promise.resolve();
  },
  // called when the user navigates to a new location, to check for authentication
  checkAuth: () => {
    const expiryString = localStorage.getItem("expiry"); // Allocating the cached expiry value as a string to allow for null type
    const currentTime = Math.floor(Date.now() / 1000);

    // Checks if a user exists, then completes a null check for the expiry and logger
    if (localStorage.getItem("authorization") && expiryString !== null) {
      const expiry = parseInt(expiryString); // Change expiry from string to int after null check

      // // Checks if the current time past the time the user logged in -> logout user and remove cached values
      if (expiry < currentTime) {
        localStorage.removeItem("authorization");
        localStorage.removeItem("role");
        localStorage.setItem("logstatus", "false");
        localStorage.removeItem("expiry");
        return Promise.reject();
        //Checks if expiry has not been passed and user is loggedout -> logout user and remove cached values
      }
      return Promise.resolve();
    }
    return Promise.reject();
  },

  // called when the person navigates to a new location, to check for permissions / roles
  getPermissions: () => {
    const permissions = localStorage.getItem("role");
    const auth = localStorage.getItem("authorization");

    if (!permissions || !auth) {
      return Promise.reject();
    } else {
      return Promise.resolve(permissions);
    }
  },

  isReadOnly: () => localStorage.getItem("role") !== "fullAccessAdmin",
};

export default AuthProvider;
