import * as React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  DateTimeInput,
  SimpleFormIterator,
  ArrayInput,
  FormDataConsumer,
  SelectInput,
} from "react-admin";
import {Box } from "@mui/material";
import authProvider from "../../authProvider";

const ValidatePollDateCreate = (values) => {
  const errors = {};
  if (!values.start) {
    errors.start = "The start date is required";
  }
  if (!values.end) {
    errors.end = "The end date is required";
  }
  if (values.start === undefined) {
    errors.start = "Invalid Date";
  }
  if (values.end === undefined) {
    errors.end = "Invalid Date";
  }
  if (!values.description) {
    errors.description = "Question is required";
  }

  return errors;
};

const PollEdit = (props) => {
  const disabled = authProvider.isReadOnly();

  return (
    <Edit
      undoable={false}
      mutationMode="optimistic"
      title={<span>Editing Temperature Check</span>}
      {...props}
    >
      <SimpleForm validate={ValidatePollDateCreate}>
        <h1>Edit Temperature Check</h1>
        <h4>Start/End:</h4>
        <DateTimeInput required source="start" disabled={disabled} />
        <DateTimeInput required source="end" disabled={disabled} />
        <h4>Description:</h4>
        <TextInput
          multiline={true}
          disabled={disabled}
          required
          source="description"
          label="Question"
        />
        <h4>Options:</h4>
        <ArrayInput source="options">
          <SimpleFormIterator inline>
            <FormDataConsumer>
              {({ getSource, scopedFormData }) => {
                return (
                  <>
                    <br></br>
                    <Box
                      sx={{
                        width: 450,
                        height: 450,
                        boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                      }}
                    >
                      <Box mt="0.5em" ml="0.5em">
                        <h4>Option:</h4>
                        <TextInput
                          source={getSource("text")}
                          record={scopedFormData}
                          step={1}
                          helperText={false}
                        />
                      </Box>
                      <Box mt="0.5em" ml="1.5em">
                        <h4>Recommendation:</h4>
                        <SelectInput
                          source={getSource("recommendation.type")}
                          record={scopedFormData}
                          label="Select Type"
                          choices={[
                            { id: "url", name: "URL" },
                            { id: "podcastEpisode", name: "Podcast Episode" },
                            { id: "podcastShow", name: "Podcast Show" },
                          ]}
                        />
                        <TextInput
                          source={getSource("recommendation.value")}
                          record={scopedFormData}
                          step={1}
                          helperText={false}
                        />
                        <TextInput
                          source={getSource("recommendation.ctaTitle")}
                          record={scopedFormData}
                          step={1}
                          helperText={false}
                          label="CTA Title"
                        />
                        <TextInput
                          source={getSource("recommendation.description")}
                          record={scopedFormData}
                          step={1}
                          helperText={false}
                          label="Description"
                        />
                      </Box>
                    </Box>
                    <br></br>
                  </>
                );
              }}
            </FormDataConsumer>
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Edit>
  );
};

export default PollEdit;
