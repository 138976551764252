import { Box } from "@mui/material";
import {
	Create,
	SimpleForm,
	TextInput,
	DateTimeInput,
	SaveButton,
	Toolbar,
	SimpleFormIterator,
	ArrayInput,
	SelectInput,
	FormDataConsumer,
} from "react-admin";

const ValidatePollDateCreate = (values) => {
	const errors = {};
	if (!values.start) {
		errors.start = "The start date is required";
	}
	if (!values.end) {
		errors.end = "The end date is required";
	}
	if (values.start === undefined) {
		errors.start = "Invalid Date";
	}
	if (values.end === undefined) {
		errors.end = "Invalid Date";
	}
	if (!values.description) {
		errors.description = "Question is required";
	}

	return errors;
};

const PollCreate = (props) => {
	return (
		<Create title="Create a Temperature Check" basePath="/polls" resource="polls" {...props}>
			<SimpleForm
				validate={ValidatePollDateCreate}
				redirect="list"
				toolbar={
					<Toolbar>
						<SaveButton />
					</Toolbar>
				}
			>
				<div>
					<h1>Create Temperature Check</h1>
				</div>
				<h4>Start/End:</h4>
				<DateTimeInput required source="start" />
				<DateTimeInput required source="end" />
				<h4>Description:</h4>
				<TextInput multiline={true} required source="description" label="Question" />

				<ArrayInput source="options">
					<SimpleFormIterator>
						<FormDataConsumer>
							{({ getSource, scopedFormData }) => {
								return (
									<>
										<br></br>
										<Box
											sx={{
												width: 450,
												height: 400,
												boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
											}}
										>
											<Box mt="0.5em" ml="0.5em">
												<h4>Option:</h4>
												<TextInput source={getSource("text")} record={scopedFormData} step={1} helperText={false} />
											</Box>
											<Box mt="0.5em" ml="1.5em">
												<h4>Recommendation:</h4>
												<SelectInput
													source={getSource("type")}
													record={scopedFormData}
													label="Select Type"
													choices={[
														{ id: "url", name: "URL" },
														{ id: "podcastEpisode", name: "Podcast Episode" },
														{ id: "podcastShow", name: "Podcast Show" },
													]}
												/>
												<TextInput source={getSource("value")} record={scopedFormData} step={1} helperText={false} />
												<TextInput
													source={getSource("ctaTitle")}
													record={scopedFormData}
													step={1}
													helperText={false}
													label="CTA Title"
												/>
												<TextInput
													source={getSource("description")}
													record={scopedFormData}
													step={1}
													helperText={false}
													label="Description"
												/>
											</Box>
										</Box>
										<br></br>
									</>
								);
							}}
						</FormDataConsumer>
					</SimpleFormIterator>
				</ArrayInput>
			</SimpleForm>
		</Create>
	);
};

export default PollCreate;
