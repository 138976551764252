import * as React from "react";
import { Create, SimpleForm, TextInput, BooleanInput } from "react-admin";
import ImageUpload from "../../subComponents/ImageUpload";
import Grid from "@mui/material/Grid";

const TabCreate = (props) => (
	<Create title="Create a Tab" {...props}>
		<SimpleForm>
			<h1>Create Tab</h1>
			<Grid container spacing={1} style={{ width: "100%" }}>
				<Grid item xs={6}>
					{/* <h4>Type:</h4>
			 <SelectInput
			 	source="type"
			 	choices={[
			 		{ id: "remote", name: "Remote" },
			 		{ id: "native", name: "Native" },
			 	]}
			 />*/}
					<h4>Title:</h4>
					<TextInput required source="title" />
					<h4>Unique Identifier:</h4>
					<TextInput required source="identifier" label="Identifier" />
					<h4>URL (If Any):</h4>
					<TextInput source="url" />
					<h4>Android URL (If Any):</h4>
					<TextInput source="androidURL" label="Android URL" />
					<h4>Icon:</h4>
					<div>
						<ImageUpload source="image" />
					</div>
				</Grid>
				<Grid item xs={6}>
					<h4>Enabled:</h4>
					<BooleanInput source="enabled" />
					<h4>Has Browser Controls:</h4>
					<BooleanInput source="hasBrowserControls" />
				</Grid>
			</Grid>
		</SimpleForm>
	</Create>
);

export default TabCreate;
