import LiveAudioStreamList from "./LiveAudioStreamList";
import LiveAudioStreamCreate from "./LiveAudioStreamCreate";
import LiveAudioStreamEdit from "./LiveAudioStreamEdit";

const resource = {
	list: LiveAudioStreamList,
	create: LiveAudioStreamCreate,
	edit: LiveAudioStreamEdit,
};

export default resource;
