import * as React from "react";
import { Admin, Resource, Layout } from "react-admin";

// Providers
import dataProvider from "./dataProvider";
import authProvider from "./authProvider";

// Models
import users from "./models/users";
import podcasts from "./models/podcasts";
import podcastCategory from "./models/podcastCategory";
import podcastGroup from "./models/podcastGroup";
import hosts from "./models/hosts";
import extras from "./models/extras";
import tabs from "./models/tabs";
import links from "./models/links";
import polls from "./models/polls";
import liveAudioStreams from "./models/liveAudioStreams";

// UI Components
import AdminSidebar from "./adminComponents/AdminSidebar";
import Appbar from "./adminComponents/Appbar";
import LoginPage from "./adminComponents/LoginPage";

const AdminLayout = (props) => <Layout {...props} appBar={Appbar} sidebar={AdminSidebar} />;

const AdminConsole = () => {
	return (
		<Admin loginPage={LoginPage} layout={AdminLayout} dataProvider={dataProvider} authProvider={authProvider}>
			<Resource name="users" {...users} />
			<Resource name="podcasts" {...podcasts} />
			<Resource name="podcastCategorys" {...podcastCategory} />
			<Resource name="liveAudioStreams" {...liveAudioStreams} />
			<Resource name="podcastGroups" {...podcastGroup} />
			<Resource name="hosts" {...hosts} />
			<Resource name="extras" {...extras} />
			<Resource name="tabs" {...tabs} />
			<Resource name="links" {...links} />
			<Resource name="polls" {...polls} />
		</Admin>
	);
};

export default AdminConsole;
