import * as React from "react";
import { List, Datagrid, TextField, DateField, DeleteButton, EditButton, CreateButton } from "react-admin";
import authProvider from "../../authProvider";

const PollList = (props: any) => {
	return (
		<>
			<div className="headingContainer">
				<h1>Temperature Checks</h1>
			</div>
			<List hasCreate={!authProvider.isReadOnly()} title="All Temperature Checks" {...props} exporter={false} bulkActionButtons={false} actions={<CreateButton />}>
				<Datagrid rowClick="edit">
					<TextField source="description" />
					<DateField
						options={{
							hour: "numeric",
							minute: "numeric",
							year: "numeric",
							month: "numeric",
							day: "numeric",
						}}
						locales="en-GB"
						source="start"
					/>
					<DateField
						options={{
							hour: "numeric",
							minute: "numeric",
							year: "numeric",
							month: "numeric",
							day: "numeric",
						}}
						locales="en-GB"
						source="end"
					/>
					<EditButton undoable={false} />
					<DeleteButton confirmTitle="Delete this temperature check?" undoable={false} />
				</Datagrid>
			</List>
		</>
	);
};

export default PollList;
