import TabList from "./TabList";
import TabCreate from "./TabCreate";
import TabEdit from "./TabEdit";

const resource = {
	list: TabList,
	create: TabCreate,
	edit: TabEdit,
};

export default resource;
