import * as React from "react";
import { Create, SimpleForm, TextInput, BooleanInput, SelectInput, ReferenceInput } from "react-admin";
import Grid from "@mui/material/Grid";
import ImageUpload from "../../subComponents/ImageUpload";

function validateFields(values) {
	const errors = {};

	if (!values.name) {
		errors.name = ["You need to choose a name"];
	}

	if (!values.description) {
		errors.description = ["You need to write a description"];
	}

	return errors;
}

const PodcastCreate = (props) => (
	<Create title="Create a Podcast" {...props}>
		<SimpleForm validate={validateFields}>
			<h1>Create Podcast:</h1>
			<Grid container spacing={1} style={{ width: "100%" }}>
				<Grid item xs={6}>
					<h4>Name:</h4>
					<TextInput required source="name" />
					<h4>Subtitle:</h4>
					<TextInput source="subname" label="Subtitle" />
					<h4>Description:</h4>
					<TextInput required source="description" />
					<h4>Primary Category:</h4>
					<ReferenceInput source="category" label="Primary Category ID" reference="podcastCategorys">
						<SelectInput optionText="name" />
					</ReferenceInput>
					<h4>ACast Feed URL:</h4>
					<TextInput source="podcastURL" label="Acast Podcast URL" />
				</Grid>
				<Grid item xs={6}>
					<h4>Featured Image:</h4>
					<div>
						<ImageUpload source="featuredImage" />
					</div>
					<h4>Podcast Image:</h4>
					<div>
						<ImageUpload source="image" />
					</div>
					<h4>Hero Image:</h4>
					<div>
						<ImageUpload source="heroImage" />
					</div>
					<h4>Show In App:</h4>
					<BooleanInput source="isEnabled" label="Show Visible In App" />
				</Grid>
			</Grid>
		</SimpleForm>
	</Create>
);

export default PodcastCreate;
