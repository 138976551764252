import * as React from "react";
import { Edit, SimpleForm, TextInput, SelectInput } from "react-admin";
import authProvider from "../../authProvider";

const UserTitle = ({ record }) => {
	return <span>Editing User {record ? `"${record.name}"` : ""}</span>;
};

const UserEdit = (props) => {
	const disabled = authProvider.isReadOnly();

	return (
		<Edit title={<UserTitle />} {...props}>
			<SimpleForm>
				<h1>Create Admin User</h1>
				<h4>Email:</h4>
				<TextInput required type="email" source="emailAddress" label="Email Address" disabled={disabled} />
				<h4>Role:</h4>
				<SelectInput
					source="role"
					choices={[
						{ id: "fullAccessAdmin", name: "Full Access" },
						{ id: "readOnlyAdmin", name: "Read Only" },
					]}
					disabled={disabled}
				/>
			</SimpleForm>
		</Edit>
	);
};

export default UserEdit;
