import CategoryIcon from "@mui/icons-material/Category";
import PodcastGroupList from "./PodcastGroupList";
import PodcastGroupCreate from "./PodcastGroupCreate";
import PodcastGroupEdit from "./PodcastGroupEdit";

const resource = {
	list: PodcastGroupList,
	create: PodcastGroupCreate,
	edit: PodcastGroupEdit,
	icon: CategoryIcon,
};

export default resource;
