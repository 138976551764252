import * as React from "react";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";

// SEN COMPONENTS
import AdminConsole from "./Admin/AdminConsole";
import ResetPassword from "./Admin/adminComponents/ResetPassword";
import TokenResetPassword from "./Admin/adminComponents/TokenResetPassword";

import "./index.css";

const App = () => {
  const isAuthed = localStorage.getItem("authorization");

  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/forgot" element={<ResetPassword />} />
        <Route
          exact
          path="/reset-password/:token"
          element={<TokenResetPassword />}
        />
        <Route exact path="/*" element={<AdminConsole />} />
        {isAuthed ? (
          <Route path="*" element={<Navigate to="/#/users" />} />
        ) : (
          <Route path="*" element={<Navigate to="/#/login" />} />
        )}
      </Routes>
    </BrowserRouter>
  );
};

export default App;
