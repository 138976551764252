import * as React from "react";
import { Edit, SimpleForm, TextInput, BooleanInput, SelectInput, ReferenceInput } from "react-admin";
import ImageUpload from "../../subComponents/ImageUpload";
import authProvider from "../../authProvider";
import Grid from "@mui/material/Grid";

const PodcastTitle = ({ record }) => {
	return <span>Editing Podcast {record ? `"${record.name}"` : ""}</span>;
};

const PodcastEdit = (props) => {
	const disabled = authProvider.isReadOnly();

	return (
		<Edit title={<PodcastTitle />} {...props}>
			<SimpleForm>
				<h1>Editing Podcast</h1>
				<Grid container spacing={1} style={{ width: "100%" }}>
					<Grid item xs={6}>
						<h4>Name:</h4>
						<TextInput required source="name" disabled={disabled} />
						<h4>Subtitle:</h4>
						<TextInput source="subname" label="Subtitle" disabled={disabled} />
						<h4>Description:</h4>
						<TextInput required source="description" disabled={disabled} />
						<h4>Primary Category:</h4>
						<ReferenceInput source="category" label="Primary Category ID" reference="podcastCategorys">
							<SelectInput optionText="name" disabled={disabled} />
						</ReferenceInput>

						<h4>ACast Feed URL:</h4>
						<TextInput source="podcastURL" label="Acast Podcast URL" disabled={disabled} />
					</Grid>
					<Grid item xs={6}>
						<h4>Featured Image:</h4>
						<div>
							<ImageUpload source="featuredImage" />
						</div>
						<h4>Podcast Image:</h4>
						<div>
							<ImageUpload source="image" />
						</div>
						<h4>Hero Image:</h4>
						<div>
							<ImageUpload source="heroImage" />
						</div>
						<h4>Show In App:</h4>
						<BooleanInput source="isEnabled" label="Show Visible In App" disabled={disabled} />
					</Grid>
				</Grid>
			</SimpleForm>
		</Edit>
	);
};

export default PodcastEdit;
