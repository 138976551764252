import * as React from "react";
import { Create, SimpleForm, TextInput } from "react-admin";
import ImageUpload from "../../subComponents/ImageUpload";

const PodcastCategoryCreate = (props) => (
	<Create title="Create a Podcast Category" {...props}>
		<SimpleForm>
			<h1>Create a Podcast Category</h1>
			<h4>Name:</h4>
			<TextInput required source="name" />
			<h4>Category Image:</h4>
			<div>
				<ImageUpload source="image" />
			</div>
		</SimpleForm>
	</Create>
);

export default PodcastCategoryCreate;
