import * as React from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { SimpleList, List, Datagrid, Filter, TextField, EditButton, FunctionField } from "react-admin";
import SortOrderIcons from "../../subComponents/SortOrderIcons";
import authProvider from "../../authProvider";

const ExtraFilter = (props) => (
	<Filter {...props}>
		<TextField label="Search" source="q" alwaysOn />
	</Filter>
);

const ExtraList = (props) => {
	const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));

	return (
		<>
			<div className="headingContainer">
				<h1>Extra Tiles</h1>
			</div>
			<List hasCreate={!authProvider.isReadOnly()} sort={{ field: "sortOrder", order: "ASC" }} title="All Extra" {...props} filters={<ExtraFilter />} bulkActionButtons={false}>
				{isSmall ? (
					<SimpleList primaryText={(record) => record.title} />
				) : (
					<Datagrid bulkActionButtons={false} rowClick="edit">
						<TextField source="title" />
						<FunctionField label="Order" render={(record) => <SortOrderIcons rowData={record} table={"extras"} />} />
						<EditButton />
					</Datagrid>
				)}
			</List>
		</>
	);
};

export default ExtraList;
