import React from "react";
import logo from "./logo-white.png";

const styles = {
	w100: {
		width: "100%",
	},
	textCenter: { textAlign: "center" },
	mainLogo: {
		width: "80%",
		maxWidth: "150px",
		"@media (max-width: 700px)": {
			maxWidth: "150px",
		},
	},
	regularText: {
		fontWeight: 500,
		fontSize: "20px",
		color: "white",
		"@media (max-width: 700px)": {
			fontSize: "15px",
		},
	},
	marginBottom20: {
		marginBottom: "20px",
	},
	marginBottom30: {
		marginBottom: "30px",
	},
	marginBottom40: {
		marginBottom: "40px",
		"@media (max-width: 1500px)": {
			marginBottom: "40px",
		},
	},
	marginBottom160: {
		marginBottom: "160px",
		"@media (max-width: 1500px)": {
			marginBottom: "30px",
		},
	},
	marginTop0: {
		marginTop: 0,
	},
	paddingLR60: {
		padding: "0px 60px",
		"@media (max-width: 700px)": {
			padding: "0 20px",
		},
	},
};

class HomeBanner extends React.Component {
	render() {
		return (
			<div style={styles.w100}>
				<div style={styles.textCenter}>
					<img src={logo} alt={"logo"} style={{ ...styles.mainLogo, ...styles.marginBottom40 }} />
					<p style={{ ...styles.regularText, ...styles.marginBottom160, ...styles.paddingLR60, ...styles.marginTop0 }}>Broad Radio</p>
				</div>
			</div>
		);
	}
}

export default HomeBanner;
