import ExtraList from "./ExtraList";
import ExtraCreate from "./ExtraCreate";
import ExtraEdit from "./ExtraEdit";

const resource = {
	list: ExtraList,
	create: ExtraCreate,
	edit: ExtraEdit,
};

export default resource;
