import * as React from "react";
import { Button, useListContext, useRefresh } from "react-admin";

import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import dataProvider from "../dataProvider";

const SortOrderIcons = (props) => {
	const { rowData, table } = props;
	const { data } = useListContext();
	const refresh = useRefresh();

	const handleSortOrderDown = (e) => {
		e.stopPropagation();
		sortOrderChange(-1);
	};

	const handleSortOrderUp = (e) => {
		e.stopPropagation();
		sortOrderChange(1);
	};

	const sortOrderChange = (add) => {
		dataProvider.update("generics", { id: "sortOrder", data: { table, add, id: rowData.id, currentSortOrder: rowData.sortOrder } }).then(() => refresh());
	};

	return (
		<div>
			<Button children={<ExpandLessIcon />} disabled={rowData.sortOrder === 0} onClick={handleSortOrderDown} />
			<Button children={<ExpandMoreIcon />} disabled={rowData.sortOrder === data.length - 1} onClick={handleSortOrderUp} />
		</div>
	);
};

export default SortOrderIcons;
