import * as React from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { SimpleList, List, Datagrid, Filter, ImageField, TextField, EditButton, FunctionField } from "react-admin";
import SortOrderIcons from "../../subComponents/SortOrderIcons";
import authProvider from "../../authProvider";

const TabFilter = (props) => (
	<Filter {...props}>
		<TextField label="Search" source="q" alwaysOn />
	</Filter>
);

const TabList = (props) => {
	const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));

	return (
		<>
			<div className="headingContainer">
				<h1>Socials</h1>
			</div>
			<List hasCreate={!authProvider.isReadOnly()} sort={{ field: "sortOrder", order: "ASC" }} title="All Tabs" {...props} filters={<TabFilter />} bulkActionButtons={false}>
				{isSmall ? (
					<SimpleList primaryText={(record) => record.title} />
				) : (
					<Datagrid bulkActionButtons={false} rowClick="edit">
						<ImageField source="imageUrl" label="Image" />
						<TextField source="type" />
						<FunctionField label="Order" render={(record) => <SortOrderIcons rowData={record} table={"social_link"} />} />

						<EditButton />
					</Datagrid>
				)}
			</List>
		</>
	);
};

export default TabList;
