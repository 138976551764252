import PodcastCategoryList from "./PodcastCategoryList";
import PodcastCategoryCreate from "./PodcastCategoryCreate";
import PodcastCategoryEdit from "./PodcastCategoryEdit";

const resource = {
	list: PodcastCategoryList,
	create: PodcastCategoryCreate,
	edit: PodcastCategoryEdit,
};

export default resource;
