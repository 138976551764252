import * as React from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { SimpleList, List, Datagrid, Filter, TextField, EditButton } from "react-admin";
import authProvider from "../../authProvider";

const UserFilter = (props) => (
	<Filter {...props}>
		<TextField label="Search" source="q" alwaysOn />
	</Filter>
);

const UserList = (props) => {
	const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));

	return (
		<>
			<div className="headingContainer">
				<h1>Users</h1>
			</div>
			<List hasCreate={!authProvider.isReadOnly()} title="All Users" {...props} filters={<UserFilter />} bulkActionButtons={false}>
				{isSmall ? (
					<SimpleList primaryText={(record) => record.emailAddress} />
				) : (
					<Datagrid bulkActionButtons={false} rowClick="edit">
						<TextField required type="email" source="emailAddress" label="Email Address" />
						<TextField required source="role" label="Role" />
						<EditButton />
					</Datagrid>
				)}
			</List>
		</>
	);
};

export default UserList;
