import * as React from "react";
import { Edit, SimpleForm, TextInput, BooleanInput, ReferenceArrayInput, SelectArrayInput } from "react-admin";
import ImageUpload from "../../subComponents/ImageUpload";
import authProvider from "../../authProvider";

const HostTitle = ({ record }) => {
	return <span>Editing Host {record ? `"${record.name}"` : ""}</span>;
};

const HostEdit = (props) => {
	const disabled = authProvider.isReadOnly();

	return (
		<Edit title={<HostTitle />} {...props}>
			<SimpleForm>
				<h1>Edit a Host</h1>
				<h4>Name:</h4>
				<TextInput required source="name" disabled={disabled} />
				<h4>Description:</h4>
				<TextInput source="description" />
				<h4>Header Image:</h4>
				<div>
					<ImageUpload source="headerImage" label="Header Image URL" disabled={disabled} />
				</div>
				<h4>Tile Image:</h4>
				<div>
					<ImageUpload source="tileImage" label="Tile Image URL" disabled={disabled} />
				</div>
				<h4>Enabled:</h4>
				<BooleanInput source="isEnabled" label="Enabled In-App" disabled={disabled} />
				<h4>Podcasts Host Appears On:</h4>
				<ReferenceArrayInput source="podcasts" label="Podcasts Featured On" reference="podcasts">
					<SelectArrayInput optionText="name" disabled={disabled} />
				</ReferenceArrayInput>
			</SimpleForm>
		</Edit>
	);
};

export default HostEdit;
