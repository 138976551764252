import * as React from "react";
import { Create, SimpleForm, TextInput } from "react-admin";
import ImageUpload from "../../subComponents/ImageUpload";

const LinkCreate = (props) => (
	<Create title="Create a Link" {...props}>
		<SimpleForm>
			<h1>Create Social Link</h1>
			<h4>Type:</h4>
			<TextInput required source="type" />
			<h4>URL:</h4>
			<TextInput required source="url" label="URL" />
			<h4>Social Image:</h4>
			<div>
				<ImageUpload source="imageUrl" />
			</div>
		</SimpleForm>
	</Create>
);

export default LinkCreate;
