import * as React from "react";
import { Button, useListContext, useRefresh } from "react-admin";
import dataProvider from "../dataProvider";

const IsFeatured = (props) => {
	const { rowData } = props;
	const { resource } = useListContext();
	const refresh = useRefresh();

	const toggleFeatured = (e) => {
		e.stopPropagation();
		dataProvider.update(resource, { id: rowData.id, data: { isFeatured: rowData.isFeatured ? 0 : 1 } }).then(() => refresh());
	};

	return (
		<Button
			className={rowData.isFeatured ? "MuiButton-containedPrimary" : ""}
			style={rowData.isFeatured ? { backgroundColor: process.env.REACT_APP_PRIMARY_COL, color: "white" } : {}}
			onClick={toggleFeatured}
			children={<div style={{ fontSize: "11px", marginLeft: "10px" }}>Featured</div>}
		></Button>
	);
};

export default IsFeatured;
