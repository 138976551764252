import HostList from "./HostList";
import HostCreate from "./HostCreate";
import HostEdit from "./HostEdit";

const resource = {
	list: HostList,
	create: HostCreate,
	edit: HostEdit,
};

export default resource;
