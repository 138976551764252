import { useController } from "react-hook-form";

import axios from "axios";

const ImageUpload = (props) => {
	const {
		field: { onChange, value },
	} = useController({ name: props.source, defaultValue: "" });

	async function getSignedUrl(event, callback) {
		event.preventDefault();
		let formData = new FormData();
		const files = event.target.files;

		if (files.length > 1) throw new Error("Please select one file only");
		else if (files.length === 1) {
			formData.append("image", files[0]);
		}

		try {
			await axios({
				method: "POST",
				url: `${process.env.REACT_APP_API_URL}/s3`,
				data: formData,
				headers: { "Content-Type": "multipart/form-data" },
			})
				.then((res) => {
					onChange(res.data.url);
					callback(res.data);
				})
				.catch((error) => {
					console.error(error);
				});
		} catch (error) {
			console.log(error);
		}
	}
	return (
		<div style={{ marginBottom: "20px" }}>
			{value && (
				<div>
					<img alt={props.source} style={{ maxWidth: "100px" }} src={value} />
				</div>
			)}
			<form>
				<input type="file" name="file" onChange={getSignedUrl}></input>
			</form>
		</div>
	);
};

export default ImageUpload;
