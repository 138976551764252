import * as React from "react";
import { Create, SimpleForm, TextInput, SelectInput } from "react-admin";

function validateFields(values) {
	const errors = {};

	if (!values.emailAddress) {
		errors.emailAddress = ["You need to choose an email"];
	}

	if (!values.password || values.password < 6) {
		errors.password = ["You need to choose a stronger password"];
	}

	return errors;
}

const UserCreate = (props) => (
	<Create title="Create a User" {...props}>
		<SimpleForm validate={validateFields}>
			<h1>Create Admin User</h1>
			<h4>Email:</h4>
			<TextInput required type="email" source="emailAddress" label="Email Address" />
			<h4>Password:</h4>
			<TextInput required type="password" source="password" label="Password" />
			<h4>Role:</h4>
			<SelectInput
				source="role"
				choices={[
					{ id: "fullAccessAdmin", name: "Full Access" },
					{ id: "readOnlyAdmin", name: "Read Only" },
				]}
			/>
		</SimpleForm>
	</Create>
);

export default UserCreate;
