import * as React from "react";
import { Edit, SimpleForm, TextInput } from "react-admin";
import ImageUpload from "../../subComponents/ImageUpload";
import authProvider from "../../authProvider";

const TabTitle = ({ record }) => {
	return <span>Editing Tab {record ? `"${record.title}"` : ""}</span>;
};

const TabEdit = (props) => {
	const disabled = authProvider.isReadOnly();

	return (
		<Edit title={<TabTitle />} {...props}>
			<SimpleForm>
				<h1>Edit Social Link</h1>
				<h4>Type:</h4>
				<TextInput required source="type" disabled={disabled} />
				<h4>URL:</h4>
				<TextInput required source="url" label="URL" disabled={disabled} />
				<h4>Social Image:</h4>
				<div>
					<ImageUpload source="imageUrl" />
				</div>
			</SimpleForm>
		</Edit>
	);
};

export default TabEdit;
