import * as React from "react";
import { Edit, SimpleForm, TextInput } from "react-admin";
import ImageUpload from "../../subComponents/ImageUpload";
import authProvider from "../../authProvider";

const PodcastCategoryTitle = ({ record }) => {
	return <span>Editing PodcastCategory {record ? `"${record.name}"` : ""}</span>;
};

const PodcastCategoryEdit = (props) => {
	const disabled = authProvider.isReadOnly();

	return (
		<Edit title={<PodcastCategoryTitle />} {...props}>
			<SimpleForm>
				<h1>Edit Podcast Category</h1>
				<h4>Name:</h4>
				<TextInput required source="name" disabled={disabled} />
				<h4>Category Image:</h4>
				<div>
					<ImageUpload source="image" />
				</div>
			</SimpleForm>
		</Edit>
	);
};

export default PodcastCategoryEdit;
