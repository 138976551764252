import * as React from "react";
import { Create, SimpleForm, TextInput, BooleanInput, ReferenceArrayInput, SelectArrayInput } from "react-admin";
import ImageUpload from "../../subComponents/ImageUpload";

const HostCreate = (props) => (
	<Create title="Create a Host" {...props}>
		<SimpleForm>
			<h1>Create a Host</h1>
			<h4>Name:</h4>
			<TextInput required source="name" />
			<h4>Description:</h4>
			<TextInput source="description" />
			<h4>Header Image:</h4>
			<div>
				<ImageUpload source="headerImage" label="Header Image URL" />
			</div>
			<h4>Tile Image:</h4>
			<div>
				<ImageUpload source="tileImage" label="Tile Image URL" />
			</div>
			<h4>Enabled:</h4>
			<BooleanInput source="isEnabled" label="Enabled In-App" />
			<h4>Podcasts Host Appears On:</h4>
			<ReferenceArrayInput source="podcasts" label="Podcasts Featured On" reference="podcasts">
				<SelectArrayInput optionText="name" />
			</ReferenceArrayInput>
		</SimpleForm>
	</Create>
);

export default HostCreate;
