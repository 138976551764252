import LinkList from "./LinkList";
import LinkCreate from "./LinkCreate";
import LinkEdit from "./LinkEdit";

const resource = {
	list: LinkList,
	create: LinkCreate,
	edit: LinkEdit,
};

export default resource;
